import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewPlanService } from '../../views/dashboard/new-plan/new-plan.service';
import { SpinnerService } from '../../shared/components/spinner/spinner.service';

@Injectable({
  providedIn: 'root'
})

export class ActivationsGuard implements CanActivate {
  constructor(
    private newPlanService: NewPlanService,
    private spinnerService: SpinnerService,
    private router: Router
  ) { }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.newPlanService.plan || this.newPlanService.plan.activations || (this.newPlanService.plan.activations && this.newPlanService.plan.activations.length === 0)) {
      this.newPlanService.createActivation();
      return this.newPlanService.savePlan(this.newPlanService.plan, true)
      .pipe(
        map((data: any) => {
          this.newPlanService.planIsBeingCreating = true;
          if (data.body.id) {
            this.router.navigate([`/dashboard/new-plan/${data.body.id}/activations/${data.body.activations[0].id}/activation-information`]);
          } else {
            return false;
          }
        })
      );
    } else {
      return true;
    }
  }
}
