import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClonePlanSnackbarComponent } from './clone-plan-snackbar.component';
import { MatButtonModule} from '@angular/material/button';


@NgModule({
  imports: [CommonModule, MatButtonModule],
  declarations: [ClonePlanSnackbarComponent],
  exports: [ClonePlanSnackbarComponent],
  entryComponents: [ClonePlanSnackbarComponent],
})
export class ClonePlanSnackbarModule {}
