import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../../core/models/user/user';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  host: { '[class.snr-user-card]': 'true', '[class.snr-user-card--flat]': 'flat' },
})
export class UserCardComponent {

  @Input() flat: boolean;
  @Input() index: number;
  @Input() user: User;
  @Input() userSelected: User;

  @Output() selected = new EventEmitter();

  click() {
    this.selected.emit();
  }
}
