import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-activation-state-chip',
  templateUrl: './activation-state-chip.component.html',
  styleUrls: ['./activation-state-chip.component.scss']
})
export class ActivationStateChipComponent {
  @Input() state: number;

  getActivationStateColor(state: number): string {
    switch (state) {
      case 0:
        return 'draft';
      case 2:
        return 'warn';
      case 3:
        return 'in-review';
      case 4:
        return 'accent';
      default:
        break;
    }
  }

}
