import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NewPlanService } from '../../views/dashboard/new-plan/new-plan.service';

@Injectable({
  providedIn: 'root'
})

export class NewPlanGuard implements CanActivate {
  constructor(private newPlanService: NewPlanService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (next && next.parent && next.parent.params && next.parent.params.id) {
      return this.newPlanService.getPlan(next.parent.params.id).pipe(
        map(res => {
          if (res) {
            if (!res.body.editable) { return this.router.parseUrl('dashboard'); }
            return true;
          }
        }));
    } else {
      return true;
    }
  }
}
