import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card.component';
import { MatCardModule, MatCheckboxModule, MatChipsModule } from '@angular/material';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';



@NgModule({
  declarations: [UserCardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    UserAvatarModule,
  ],
  exports: [UserCardComponent],
})
export class UserCardModule { }
