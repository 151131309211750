import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressSpinnerModule } from '@angular/material';
import { UserCardModule } from '../user-card/user-card.module';
import { ChangePlanOwnerDialogComponent } from './change-plan-owner-dialog.component';


@NgModule({
  declarations: [ChangePlanOwnerDialogComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    UserCardModule,
  ],
  entryComponents: [ChangePlanOwnerDialogComponent]
})
export class ChangePlanOwnerDialogModule {}
