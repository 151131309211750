import { Currency } from "../currency";

export class User {
    currencyDefault?: Currency;
    dateRequest?: string;
    email?: string;
    enable?: boolean;
    enabled?: boolean;
    firstName?: string;
    id?: number;
    lastName?: string;
    markets?: Array<any>;
    multiple?: boolean;
    photoURL?: string;
    role?: string;
    uid?: string;
    userBrandsLevel1?: Array<any>;
    userCountries?: Array<any>;

    constructor() {
        this.email = null;
        this.enable = null;
        this.enabled = null;
        this.firstName = null;
        this.id = null;
        this.lastName = null;
        this.markets = [];
        this.multiple = null;
        this.photoURL = null;
        this.role = null;
        this.uid = null;
        this.userBrandsLevel1 = [];
        this.userCountries = null;
    }

}
