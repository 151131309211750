import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material';
import { ActivationStateChipComponent } from './activation-state-chip.component';



@NgModule({
  declarations: [ActivationStateChipComponent],
  imports: [
    CommonModule,
    MatChipsModule
  ],
  exports: [ActivationStateChipComponent]
})
export class ActivationStateChipModule { }
